<template>
	<div class="app-container">
		<div class="filter-container">
			<!-- 关键字 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-select v-model="searchType" style="width: 150px;margin-right: 10px;">
					<el-option v-for="item in searchList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
				<el-input v-model="searchKey" @keyup.enter.native="goodsFilter" :placeholder="placeholders[searchType]" style="width: 250px;;margin-right: 10px;"
				clearable></el-input>
			</div>
			<!-- 评价时间 -->
			<div class="filter-item">
				<label class="label">评价时间: </label>
				<el-date-picker v-model="commentTimes" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
				start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				<!-- <el-date-picker type="date" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd"
				format="yyyy-MM-dd"></el-date-picker> ~
				<el-date-picker type="date" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd"
				format="yyyy-MM-dd"></el-date-picker> -->
			</div>
			<!--评价星级  -->
			<!-- <div class="filter-item">
				<label class="label">评价星级: </label>
				<el-select v-model="stars" placeholder="请选择">
					<el-option v-for="item in starsList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div> -->
			<!--审核状态  -->
			<div class="filter-item">
				<label class="label">审核状态: </label>
				<el-select v-model="review" placeholder="请选择">
					<el-option v-for="item in reviewList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!--查询 -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="goodsFilter">查询</el-button>
				<button-permissions :datas="'batchPass'">
					<el-button type="primary" v-if="!reviewType" @click="bathOrrders">批量审核通过</el-button>
				</button-permissions>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="dataList" style="width: 100%;" :row-key="getRowKeys" :row-class-name="setClassName" v-loading="loading"
			@select-all="handleSelectAll" @selection-change="handleSelectionChange" @select='handleSingleSelect'
			default-expand-all ref="ruleForm">

				<!-- <el-table-column type="expand">
					<template slot-scope="scope">
						<div style="margin:20px 0;" v-if="scope.row.ReplyList">
							<div class="reply-expand" :class="{'customer-reply':item.ReplyRoleType==1}" v-for="(item ,index) in scope.row.ReplyList"
							:key="index">
								<div style="width:10px;">
									<el-checkbox v-model="item.checkRadio" v-if="item.ReplyRoleType!=1&&(!reviewType)" @change="checkRadioReply(scope.row,item.checkRadio)"></el-checkbox>
								</div>
								<div class="title">
									<span v-if="item.ReplyRoleType==1">商家回复：</span>
									<span v-else>买家追评：</span>
								</div>
								<div class="content">
									<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" :content="item.Content"
									placement="top-start">
										<div class="content-text">
											{{item.Content}}
										</div>
									</el-tooltip>
									<div class="content-time">
										{{item.AddTime}}
									</div>
									<div>
										<img @click="handleChangeImg(item.PicUrlsComplete,ii)" v-for="(iitem,ii) in item.PicUrls" :key="ii" :src='imgUrl + iitem'
										style="width:40px;height:40px;object-fit:contain;margin-right: 10px;border:1px solid #ddd;cursor:pointer;">
									</div>
								</div>
								<div class="handle-button" v-if="item.ReplyRoleType!=1">
									<button-permissions :datas="'passScore'" style="margin-right:10px;">
										<el-button type="text" @click="handleReview(scope.row.reviewId,item.reviewId,0) " v-if="!reviewType">审核通过</el-button>
									</button-permissions>
									<button-permissions :datas="'deleteScore'">
										<el-button type="text" @click="handleDelete(0,item.reviewId)" style="color:#F56C6C" v-if="!reviewType">删除</el-button>
										<el-button type="text" @click="handleDelete(0,item.reviewId)" style="color:#F56C6C" v-if="reviewType">删除</el-button>
									</button-permissions>
								</div>
							</div>
						</div>
					</template>
				</el-table-column> -->

				<!-- :class="{'disable-selection':selectInit}" -->
				<el-table-column type="selection" width="55" v-if="!reviewType"></el-table-column>
				<el-table-column label="商品" width="270px">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img style="width:60px;height:60px;object-fit:contain;margin-right: 10px;border:1px solid #ddd;" :src="scope.row.skuImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'" />
							<div class="right">
								<div class="name">
									{{scope.row.goodsName}}
								</div>
								<div style="color:#909399;font-size:12px;">
									{{scope.row.specValues}}
									<!-- <span v-if="scope.row.SpecValue!=''">;</span>
									{{scope.row.SpecValue2}} -->
								</div>
								<div style="color:#409EFF;cursor:pointer;" @click="jumpToDetail(scope.row.orderId)">
									{{scope.row.orderNo}}
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="评价内容" width="270px;">
					<template slot-scope="scope">
						<!-- <el-tooltip class="item-tooltip" effect="light" popper-class="comment-reply-item-tooltip" :content="scope.row.Content"
						placement="top-start"> -->
							<div class="comment-content">{{scope.row.reviewContent}}</div>
						<!-- </el-tooltip> -->
						<img @click="handleChangeImg(scope.row.reviewImgUrlList,index)" v-for="(item,index) in scope.row.reviewImgUrlList" :key="index"
						style="width:60px;height:60px;object-fit:contain;margin-right: 10px;border:1px solid #ddd;cursor:pointer;" :src='item'>
					</template>
				</el-table-column>
				<el-table-column label="星级评价" min-width="125px;">
					<template slot-scope="scope">
						<el-rate v-model="scope.row.avgStars" disabled score-template="{scope.row.avgStars}"></el-rate>
					</template>
				</el-table-column>
				<el-table-column label="服务评价" min-width="125px;">
					<template slot-scope="scope">
						商家服务
						<el-rate style="margin-top:5px;" v-model="scope.row.goodsStars" disabled score-template="{scope.row.goodsStars}"></el-rate>
						物流服务
						<el-rate style="margin-top:5px;" v-model="scope.row.serviceStars" disabled score-template="{scope.row.serviceStars}"></el-rate>
						商品描述
						<el-rate style="margin-top:5px;" v-model="scope.row.descriptionStars" disabled score-template="{scope.row.descriptionStars}"></el-rate>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="评价时间"></el-table-column>
				<el-table-column label="操作" width="200px;">
					<template slot-scope="scope">
						<!-- <div v-if="scope.row.ReplyList.length"> -->
						<div v-if="!reviewType">
							<button-permissions :datas="'passScore'" style="margin-right:10px;">
								<el-button type="text" @click="handleReview(scope.row.reviewId,0,0)">审核通过</el-button>
							</button-permissions>
							<button-permissions :datas="'deleteScore'">
								<el-button type="text" @click="handleDelete(scope.row.reviewId,0)" style="color:#F56C6C">删除</el-button>
							</button-permissions>
						</div>
						<div v-if="reviewType">
							<!-- <button-permissions :datas="'replyScore'" style="margin-right:10px;">
								<el-button type="text" v-if="scope.row.lastComment" @click="handleReply(scope.row.reviewId)">回复</el-button>
							</button-permissions> -->

							<button-permissions :datas="'deleteScore'">
								<el-button type="text" @click="handleDelete(scope.row.reviewId,0)" style="color:#F56C6C">删除</el-button>
							</button-permissions>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="page.total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				:total="page.total">
				</el-pagination>
			</div>
		</div>

		<!--商家回复 -->
		<el-dialog title="商家回复" :visible.sync="editReplyShow" width="600px" class="dialog data-dialog">
			<div class="disagree-dialog">
				<el-form>
					<el-form-item label="回复内容：" required>
						<el-input type="textarea" :rows="5" maxlength="200" style="width:450px;" placeholder="请输入回复内容" v-model="replyContent"></el-input>
					</el-form-item>
				</el-form>
				<div class="footer">
					<el-button style="width:145px;" @click="cancleEditReply">关闭</el-button>
					<el-button style="width:145px;" type="primary" v-loading='loading' @click="saveEditReply">确认</el-button>
				</div>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="imgZoomShow">
			<el-carousel v-if="imgZoomShow" height='600px' :initial-index='initialIndex' arrow="always" :autoplay='false'>
				<el-carousel-item v-for="item in imgList" :key="item">
					<img style="width:100%;height:100%;object-fit:contain" :src="item">
				</el-carousel-item>
			</el-carousel>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				checkRadio: false,
				imgUrl: config.IMG_BASE,
				loading: false,
				searchType: 0,
				searchList: [{
						value: 0,
						label: '订单号'
					},
					{
						value: 1,
						label: '商品'
					}
				],
				searchKey: '',
				placeholders: ['订单编号', '商品名称、商品编码'],
				starTime: '',
				endTime: '',
				stars: null,
				starsList: [{
						value: null,
						label: '全部'
					},
					{
						value: 1,
						label: '1星'
					},
					{
						value: 2,
						label: '2星'
					},
					{
						value: 3,
						label: '3星'
					},
					{
						value: 4,
						label: '4星'
					},
					{
						value: 5,
						label: '5星'
					}
				],
				review: 0,
				reviewType: 0,
				reviewList: [{
						value: 0,
						label: '待审核'
					},
					{
						value: 1,
						label: '审核通过'
					}
				],
				dataList: [],
				page: {
					total: 0,
					size: 20,
					current: 1
				},
				multipleSelection: [],
				editReplyShow: false,
				replyContent: '',
				CommentId: 0,
				imgZoomShow: false,
				imgList: [],
				initialIndex: 0,
				commentTimes: '',
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		created() {
			this.getData()
		},
		methods: {
			jumpToDetail(id) {
				let params = {
					Id: id ? id : 0
				}
				this.publicToDetails(params, '/order/orderDetailpage', this);
				// this.$router.push({
				// 	path: '/order/orderDetailpage',
				// 	query: {
				// 		Id: Id
				// 	}
				// })
			},
			//选择replycheck
			checkRadioReply(row, selected) {
				this.$forceUpdate()
				if (selected) {
					this.$nextTick(function() {
						this.$refs.ruleForm.toggleRowSelection(row, selected)
					})
				}
			},
			selectInit(row) {
				return row.CheckState ? false : true
			},
			getRowKeys(row) {
				return row.reviewId;
			},
			setClassName({
				row
			}) {
				// 通过自己的逻辑返回一个class或者空
				let length = 0
				if (row && row.ReplyList) {
					length = row.ReplyList.length
				}
				if (!length) {
					return 'unexpand'
				} else {
					if (row.CheckState == 1) {
						return 'hiddenCheck'
					} else {
						return ''
					}
				}
			},
			async getData() {
				this.loading = true
				try {
					if (this.commentTimes) {
						this.starTime = this.commentTimes[0];
						this.endTime = this.commentTimes[1];
					} else {
						this.starTime = '';
						this.endTime = '';
					}
					let data = {
						startTime: this.starTime,
						endTime: this.endTime,
						//stars: this.stars,
						auditStatus: this.review,
						pageNo: this.page.current,
						pageSize: this.page.size,
					}
					if (this.searchType == 0) {
						data.orderNo = this.searchKey;
					} else if (this.searchType == 1) {
						data.keyword = this.searchKey;
					}
					let result = await apiList.commentList(data);
					if (result.success) {
						result.data.records.map(item => {
							item.CheckState = 0;
							return item;
						})
						result.data.records.map(item=>{
							item.reviewImgUrlList = JSON.parse(item.reviewImgUrls);
							return item;
						})
						this.dataList = result.data.records;
						this.page.total = result.data.total;
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
					// this.dataList = result.Result.Results

					// if (this.review) {
					// 	//判断最后一条评论是商家还是买家，lastComment记录0：商家；1：买家
					// 	this.dataList.forEach(t => {
					// 		let length = 0
					// 		t.lastComment = 0
					// 		if (t.ReplyList) {
					// 			length = t.ReplyList.length
					// 			if (t.ReplyList[length - 1].ReplyRoleType == 2) {
					// 				t.lastComment = 1
					// 			}
					// 		} else {
					// 			t.lastComment = 1
					// 		}
					// 	})
					// } else {
					// 	this.dataList.forEach(t => {
					// 		if (t.ReplyList) {
					// 			t.ReplyList = t.ReplyList.map(tt => {
					// 				if (tt.ReplyRoleType == 2) {
					// 					return {
					// 						...tt,
					// 						checkRadio: false
					// 					}
					// 				} else {
					// 					return {
					// 						...tt
					// 					}
					// 				}
					// 			})
					// 		}
					// 	})
					// }
					// this.page.total = result.Result.Total
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
				this.reviewType = this.review
			},
			goodsFilter() {
				this.getData()
			},
			//手动全选
			handleSelectAll(val) {
				console.log(val)
				// let length = this.dataList.length
				// if(length==val.length){
				//   this.handleAllCheck()
				// }else{
				//   this.handleAllUnCheck()
				// }
			},
			//当用户手动勾选单行数据行的 Checkbox 时触发的事件,如果勾选了主评论，追评不check，如果取消勾选主评，那么追评也取消
			handleSingleSelect(selection, row) {
				let index = 0 //0:checkbox为false；1：true
				if (!selection.length) {
					index = 0
				} else {
					for (let i = 0; i < selection.length; i++) {
						if (selection[i].reviewId == row.reviewId) {
							index = 1
							break
						}
					}
				}
				if (!index) {
					if (row.ReplyList && row.ReplyList.length) {
						row.ReplyList = row.ReplyList.map(tt => {
							if (tt.ReplyRoleType == 2) {
								return {
									...tt,
									checkRadio: false
								}
							} else {
								return {
									...tt
								}
							}
						})
					}
				}
			},
			//设置所有的reply为check
			handleAllCheck() {
				this.dataList.forEach(t => {
					if (t.ReplyList) {
						t.ReplyList = t.ReplyList.map(tt => {
							if (tt.ReplyRoleType == 2) {
								return {
									...tt,
									checkRadio: true
								}
							} else {
								return {
									...tt
								}
							}
						})
					}
				})
			},
			//取消所有的reply的check
			handleAllUnCheck() {
				this.dataList.forEach(t => {
					if (t.ReplyList) {
						t.ReplyList = t.ReplyList.map(tt => {
							if (tt.ReplyRoleType == 2) {
								return {
									...tt,
									checkRadio: false
								}
							} else {
								return {
									...tt
								}
							}
						})
					}
				})
			},
			//当table选择项发生变化时会触发该事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//批量审核
			bathOrrders() {
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						type: 'info',
						message: '请选择需要审核的评价'
					})
				} else {
					let CommentIdList = []
					let ReplyIdList = []
					this.multipleSelection.forEach(t => {
						CommentIdList.push(t.reviewId)
						// if (t.ReplyList && t.ReplyList.length) {
						// 	t.ReplyList.forEach(tt => {
						// 		if (tt.checkRadio) {
						// 			ReplyIdList.push(tt.reviewId)
						// 		}
						// 	})
						// }
					})
					this.saveReview(CommentIdList, ReplyIdList)
				}
			},
			//审核
			handleReview(CommentId, ReplyId, index) {
				let CommentIdList = []
				let ReplyIdList = []
				if (!index) {
					CommentIdList.push(CommentId)
					ReplyIdList.push(ReplyId)
				}
				this.saveReview(CommentIdList)
			},
			//确认审核
			saveReview(CommentIdList) {
				this.$confirm('审核通过后，评价内容将显示在前台商品详情页，是否确认审核通过？', {
					confirmButtonText: '确认',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					this.loading = true
					let result = await apiList.commentBatchCheck({
						reviewIds: CommentIdList
					})
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '审核成功!'
						});
						this.getData()
						this.$nextTick(() => {
							this.$refs.ruleForm.clearSelection()
						})
						this.handleAllUnCheck()
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消审核'
					});
					this.loading = false
				}).finally(() => {
					this.loading = false
				})
			},
			//删除
			handleDelete(CommentId) {
				this.$confirm('是否确认删除这条评价？删除后不可恢复', {
					confirmButtonText: '确认',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					try {
						this.loading = true
						let result = await apiList.commentDelete({
							reviewId: CommentId
						})
						console.log(result);
						if (result.success) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getData()
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
						this.loading = false
					} catch (e) {
						this.loading = false
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {})
			},
			//回复
			handleReply(id) {
				this.replyContent = ''
				this.CommentId = id
				this.editReplyShow = true
			},
			//取消商家回复
			cancleEditReply() {
				this.editReplyShow = false
			},
			//确认商家回复
			async saveEditReply() {
				if (this.replyContent == '') {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入回复内容'
					})
				} else {
					try {
						this.loading = true
						let result = await apiList.commentMallReply({
							CommentId: this.CommentId,
							Content: this.replyContent
						})
						console.log(result);
					} catch (e) {
						this.loading = false
					} finally {
						this.loading = false
						this.getData()
					}
					this.editReplyShow = false
				}
			},
			//放大图片
			handleChangeImg(imgList, index) {
				this.imgList = imgList
				this.initialIndex = index
				this.imgZoomShow = true
			},
			handleSizeChange(val) {
				this.page.size = val
				this.getData()
			},
			handleCurrentChange(val) {
				this.page.current = val
				this.getData()
			},
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;

		.filter-item {
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;
		align-items: center;

		.name {
			width: 180px;
			// overflow: hidden;
			// text-overflow: ellipsis;
			// display: -webkit-box;
			// -webkit-line-clamp: 1;
			// -webkit-box-orient: vertical;
		}
	}

	.comment-content {
		width: 245px;
		margin-bottom: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}

	.reply-expand {
		/deep/ .el-table th {
			padding: 0 !important;
		}

		// margin: 5px 0;
		width: 100%;
		margin-bottom:5px;
		border: 1px solid #409EFF;
		color: #409EFF;
		background-color: #D9ECFF;
		display: flex;
		flex-direction: row;
		padding: 10px;

		.title {
			margin-left: 35px;
		}

		.content {
			width: calc(100% - 355px);
			color: #606266;

			.content-text {
				width: 100%;
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}

			.content-time {
				line-height: 40px;
			}
		}

		.handle-button {
			margin-left: 10px;
		}
	}

	.customer-reply {
		border: 1px solid #E6A23C;
		background-color: #FDF6EC;
		color: #E6A23C;

		.content {
			.content-text {
				color: #E6A23C;
			}
		}

		.title {
			// margin-left:45px;
		}
	}

	.data-dialog {
		/deep/.el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}

		.footer {
			text-align: center;
		}
	}

	/deep/.unexpand .el-table__expand-column .cell {
		display: none;
	}

	/deep/.unexpand .el-table__expanded-cell {
		display: none;
	}

	/deep/.el-table__expanded-cell[class*=cell] {
		padding: 0 0 0 50px;
	}

	/deep/.el-table__expanded-cell {
		padding-left: 50px;
	}

	/deep/.hiddenCheck .el-checkbox__input {
		visibility: hidden;
	}
</style>
<style lang="less">
	.comment-reply-item-tooltip {
		max-width: 530px !important;
	}
</style>
